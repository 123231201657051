import { Injectable } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { WorkflowInfo } from '@selfai-platform/pipeline-common';
import { INamespace, IFunctionScope } from '../models';

export class UserFunctionsControls {
  public static createScopeList(workflows: WorkflowInfo[], namespaces: INamespace[]): TreeNode[] {
    return [
      {
        key: '1',
        label: 'Workflows',
        icon: 'pi pi-fw pi-pencil',
        children: workflows.map((workflow) => {
          return {
            key: workflow.id,
            label: workflow.name,
            data: { scopeId: workflow.id, scopeType: 'workflow' },
          };
        }),
      },
      {
        key: '2',
        label: 'Namespaces',
        icon: 'pi pi-fw pi-pencil',
        children: namespaces.map((namespace) => {
          return {
            key: namespace.id,
            label: namespace.name,
            data: { scopeId: namespace.id, scopeType: 'namespace' },
          };
        }),
      },
    ] as TreeNode[];
  }

  public static mapScopesValuesToControl(scopes: IFunctionScope[], scopeList: TreeNode[]): TreeNode[] {
    const controlScopes: TreeNode[] = [];
    scopes.forEach((scope) => {
      controlScopes.push(
        scopeList.find((list) => list.data === scope.scopeType).children.find((child) => child.key === scope.scopeId),
      );
    });
    return controlScopes;
  }
}
