import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, tap } from 'rxjs';
import { Update } from '@ngrx/entity/src/models';
import { PipelineConfigService } from '@selfai-platform/shared';
import { IFunctionImportMessages, IFunctionScope, UserFunctionApi, IFunctionTag, INamespace } from '../models';
import { API_RESPONSE_MESSAGES } from '../constants';
import { FunctionsResponseCallbacksService } from './functions-response-callbacks.service';

@Injectable({
  providedIn: 'root',
})
export class UserFunctionsApiService {
  private readonly config = this.pipelineConfigService.getConfig();
  private readonly apiUrl = `${this.config.hosts.api}/${this.config.versions.url}`;
  private readonly userFunctionsPath = 'functions';
  private readonly tagsPath = 'tags';
  private readonly namespacesPath = 'namespaces';

  constructor(
    private readonly http: HttpClient,
    private readonly pipelineConfigService: PipelineConfigService,
    private readonly functionsResponseCallbackService: FunctionsResponseCallbacksService,
  ) {}

  public loadList(): Observable<UserFunctionApi[]> {
    return this.http.post<UserFunctionApi[]>(`${this.apiUrl}/${this.userFunctionsPath}/list`, {});
  }

  public loadTagsList(): Observable<IFunctionTag[]> {
    return this.http.get<IFunctionTag[]>(`${this.apiUrl}/${this.userFunctionsPath}/${this.tagsPath}/list`);
  }

  public loadNamespacesList(): Observable<INamespace[]> {
    return this.http.get<INamespace[]>(`${this.apiUrl}/${this.userFunctionsPath}/${this.namespacesPath}/list`);
  }

  public loadUserFunction(id: string): Observable<UserFunctionApi> {
    return this.http.get<UserFunctionApi>(`${this.apiUrl}/${this.userFunctionsPath}/${id}`);
  }

  public addUserFunction(userFunctionParams: UserFunctionApi): Observable<UserFunctionApi> {
    return this.http
      .post(`${this.apiUrl}/${this.userFunctionsPath}`, userFunctionParams,{
        headers: {
          Accept: 'text/plain',
        },
        responseType: 'text',
      })
      .pipe(
        map((id) => ({ ...userFunctionParams, id } as UserFunctionApi))
      );
  }

  public updateUserFunction(data: Update<UserFunctionApi>): Observable<UserFunctionApi> {
    return this.http
      .put(`${this.apiUrl}/${this.userFunctionsPath}/${data.id}`, data.changes, {
        headers: {
          Accept: 'text/plain',
        },
        responseType: 'text',
      })
      .pipe(
        map(() => data.changes as UserFunctionApi),
      );
  }

  public deleteUserFunction(id: string): Observable<string> {
    return this.http.delete<string>(`${this.apiUrl}/${this.userFunctionsPath}/${id}`);
  }

  public exportUserFunctions(ids: string[]): Observable<unknown> {
    return this.http.post(`${this.apiUrl}/${this.userFunctionsPath}/export`, { ids })
      .pipe(
        tap({
          next: () => this.functionsResponseCallbackService.handleNextCallback(API_RESPONSE_MESSAGES.EXPORT_SUCCESS, false),
          error: ({ error }) => this.functionsResponseCallbackService.handleErrorCallback(API_RESPONSE_MESSAGES.EXPORT_ERROR, JSON.stringify(error)),
        }),
      );
  }

  public importUserFunctions(userFunctions: UserFunctionApi[], scopes: IFunctionScope[], override: boolean = false): Observable<IFunctionImportMessages[]> {
    let url = `${this.apiUrl}/${this.userFunctionsPath}/import?override=${override}`;
    if (scopes?.length) {
      const scopesIds = scopes.map(scope => scope.scopeId).join(',');
      url = url + '&scopes=' + scopesIds;
    }
    return this.http.post<IFunctionImportMessages[]>(url, userFunctions)
      .pipe(
        tap({
          next: (messages) => {
            if (messages.find(message => message.success)) {
              this.functionsResponseCallbackService.handleNextCallback(API_RESPONSE_MESSAGES.IMPORT_SUCCESS, false);
            }
          },
          error: ({ error }) => this.functionsResponseCallbackService.handleErrorCallback(API_RESPONSE_MESSAGES.IMPORT_ERROR, JSON.stringify(error)),
        }),
      );
  }

  public cloneUserFunction(id: string, newFunctionName: string): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}/${this.userFunctionsPath}/${id}/clone`, newFunctionName)
      .pipe(
        tap({
          next: () => this.functionsResponseCallbackService.handleNextCallback(API_RESPONSE_MESSAGES.CLONE_SUCCESS, false),
          error: ({ error }) => this.functionsResponseCallbackService.handleErrorCallback(API_RESPONSE_MESSAGES.CLONE_ERROR, JSON.stringify(error)),
        })
      )
  }

  public deleteUserFunctionTag(id: string): Observable<string> {
    return this.http.delete<string>(`${this.apiUrl}/${this.userFunctionsPath}/${this.tagsPath}/${id}`)
  }
}
