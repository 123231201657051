import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { IFunctionTag, UserFunctionsList, UserFunctionsListItem } from '../models';
import { UserFunctionsListStore } from '../store';

@Injectable({ providedIn: 'root' })
export class UserFunctionsListDomainService {
  constructor(private userFunctionsListStore: UserFunctionsListStore) {}

  public getFilteredFunctionsList(): Observable<UserFunctionsListItem[]> {
    return this.userFunctionsListStore.filteredEntities$.pipe() as Observable<UserFunctionsListItem[]>;
  }

  public loadUserFunctionsList(): Observable<UserFunctionsList> {
    return this.userFunctionsListStore.getAll()
      .pipe(map((userFunctions) => ({ userFunctions })));
  }

  public filterFunctionList(tags: IFunctionTag[]) {
    return this.userFunctionsListStore.setFilter(tags);
  }

  public getUserFunctionsListLoading(): Observable<boolean> {
    return this.userFunctionsListStore.loading$ as Observable<boolean>;
  }

  public getUserFunctionsListError(): Observable<unknown> {
    return this.userFunctionsListStore.errors$ as Observable<unknown>;
  }

  public getUserFunctionsListLoaded(): Observable<boolean> {
    return this.userFunctionsListStore.loaded$ as Observable<boolean>;
  }

  public deleteUserFunction(id: string): void {
    this.userFunctionsListStore.delete(id);
  }
}
