import { Injectable } from '@angular/core';
import { WorkflowPresetsApiService } from '../../workflow-presets/store/workflow-presets-api.service';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { WorkflowPresetsInterface } from '../../workflow-presets';
import { HttpClient } from '@angular/common/http';
import { KE_SCHEDULES_PRESETS_ENTITY_NAME } from '@selfai-platform/store';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SchedulesPresetsListDataService extends DefaultDataService<WorkflowPresetsInterface> {
  constructor(
    public override http: HttpClient,
    public override httpUrlGenerator: HttpUrlGenerator,
    private readonly schedulesPresetsListApiService: WorkflowPresetsApiService
  ) {
    super(KE_SCHEDULES_PRESETS_ENTITY_NAME, http, httpUrlGenerator);
  }

  override getAll(): Observable<WorkflowPresetsInterface[]> {
    return this.schedulesPresetsListApiService
      .getAllPresets()
      .pipe(map((items) => items.map((item) => ({ ...item, name: item.name }))));
  }
}
