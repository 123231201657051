import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, EntityDataService } from '@ngrx/data';
import { KE_SCHEDULES_PRESETS_ENTITY_NAME } from '@selfai-platform/store';
import { WorkflowPresetsInterface } from '../../workflow-presets';
import { SchedulesPresetsListDataService } from './presets-list-data.service';

@Injectable({ providedIn: 'root' })
export class SchedulesPresetsListStore extends EntityCollectionServiceBase<WorkflowPresetsInterface> {
  constructor(
    public entityDataService: EntityDataService,
    public schedulesDataService: SchedulesPresetsListDataService,
    public serviceElementsFactory: EntityCollectionServiceElementsFactory,
  ) {
    entityDataService.registerService(KE_SCHEDULES_PRESETS_ENTITY_NAME, schedulesDataService);
    super(KE_SCHEDULES_PRESETS_ENTITY_NAME, serviceElementsFactory);
  }

  [name: string]: unknown;
}
