import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  map,
  Observable,
  of,
  take,
  toArray,
  concatMap,
  from,
  combineLatest, EMPTY,
} from 'rxjs';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import {
  convertMessageToHtml,
  DataListViewComponentService,
  GroupAction,
  KE_ROOT_ROUTE,
  KE_USER_FUNCTIONS_PATH,
  MenuItemsNormalizerService,
  SelectedItemsService,
  TableColumn,
} from '@selfai-platform/shell';
import { PipelinePermission, UrlPageParamsService } from '@selfai-platform/shared';
import { IFunctionTag, UserFunctionsListItem } from '../models';
import { USER_FUNCTIONS_TABLE_COLUMNS_CONFIG } from '../constants';
import { UserFunctionsListDomainService } from './user-functions-list-domain.service';
import { UserFunctionsPermissionService } from './user-functions-permission.service';

@Injectable()
export class UserFunctionsDataListViewService extends DataListViewComponentService<UserFunctionsListItem> {
  constructor(
    private readonly userFunctionsListDomainService: UserFunctionsListDomainService,
    private readonly userFunctionsPermissionsService: UserFunctionsPermissionService,
    private readonly confirmationService: ConfirmationService,
    private readonly translate: TranslateService,
    private readonly router: Router,
    private readonly menuItemsNormalizerService: MenuItemsNormalizerService,
    urlPageParamsService: UrlPageParamsService,
    selectedItemService: SelectedItemsService<UserFunctionsListItem>
  ) {
    super(urlPageParamsService, selectedItemService);
  }

  override loadData(): Observable<{ items: UserFunctionsListItem[]; totalItems: number }> {
    return this.userFunctionsListDomainService.loadUserFunctionsList().pipe(
      map(({ userFunctions }) => ({
        items: userFunctions as UserFunctionsListItem[],
        totalItems: userFunctions.length,
      })),
    );
  }

  override getData(): Observable<UserFunctionsListItem[]> {
    return this.userFunctionsListDomainService.getFilteredFunctionsList().pipe(
      map((functionsList) => functionsList),
      concatMap((streamedFunctionList) => from(streamedFunctionList).pipe(
        concatMap((streamedUserFunction: UserFunctionsListItem) =>
          combineLatest([
            of(this.normalizeForView(streamedUserFunction)),
            this.getRouterLink(streamedUserFunction),
          ]).pipe(
            map(([userFunction, routerLink]) => ({ ...userFunction, ...routerLink }))
          )
        ),
        toArray()
        )
      ),
    );
  }

  private getRouterLink(userFunction: UserFunctionsListItem): Observable<UserFunctionsListItem> {
    return this.userFunctionsPermissionsService.canViewUserFunction(userFunction.ownerId).pipe(map((canView) => {
      if (canView) {
        return {
          routerLinkToItem: {
            routerLink: ['/', KE_ROOT_ROUTE, KE_USER_FUNCTIONS_PATH, 'edit', userFunction.id],
          },
        } as UserFunctionsListItem
      } else {
        return null;
      }
    }));
  }

  override isLoading(): Observable<boolean> {
    return this.userFunctionsListDomainService.getUserFunctionsListLoading();
  }

  override isLoaded(): Observable<boolean> {
    return this.userFunctionsListDomainService.getUserFunctionsListLoaded();
  }

  override hasError(): Observable<boolean> {
    return this.userFunctionsListDomainService.getUserFunctionsListError().pipe(map((error) => !!error));
  }

  public getColumns(): TableColumn<UserFunctionsListItem>[] {
    return USER_FUNCTIONS_TABLE_COLUMNS_CONFIG as TableColumn<UserFunctionsListItem>[];
  }

  public getGroupActions(): Observable<GroupAction[]> {
    if (this.userFunctionsPermissionsService.hasCurrentPermission(PipelinePermission.UserFunctionsDeleteAny)) {
      return of([{
        tooltipTranslate: 'user-functions-list.toolbar.actions.delete-selected',
        icon: 'pi pi-trash',
        buttonClass: 'p-button-danger',
        action: () => this.deleteSelected(),
      }]) as Observable<GroupAction[]>;
    } else {
      return EMPTY;
    }
  }

  public filterData(tags: IFunctionTag[] = []): void {
    this.userFunctionsListDomainService.filterFunctionList(tags);
  }

  private deleteSelected(): void {
    this.confirmationService.confirm({
      key: 'dialog',
      message: convertMessageToHtml(
        this.translate.instant('user-functions-list.toolbar.actions.delete-selected.confirmation'),
      ),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.selectedItemsService
          .getSelectedItemIds()
          .pipe(take(1))
          .subscribe((ids) => {
            ids.forEach((datasourceId) => {
              this.userFunctionsListDomainService.deleteUserFunction(datasourceId);
            });
          });
      },
    });
  }

  private normalizeForView(userFunction: UserFunctionsListItem): UserFunctionsListItem {
    return {
      ...userFunction,
      name: userFunction.name,
      tags: userFunction.tags?.map(t => (t as IFunctionTag).name),
      scopeType: userFunction.scopes?.map((scope) => scope.scopeType),
      icon: 'pi pi-wave-pulse',
      iconClass: 'text-purple-500',
    };
  }
}
