import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkflowPresetsComponent } from '../../components/workflow-presets/workflow-presets.component';
import { Observable } from 'rxjs';
import { WorkflowPresetsInterface } from '../../interfaces/workflow-presets.interface';
import { ActivatedRoute } from '@angular/router';
import { HistoryBackService } from '@selfai-platform/shared';
import { WorkflowPresetsDomainService } from '../../services/workflow-presets-domain.service';
import { getPresetsPath } from '../../functions';

const DEFAULT_EXECUTOR_CORES = 2;
const DEFAULT_NUMB_OF_EXECUTORS = 2;

@Component({
  selector: 'selfai-platform-workflow-presets-container',
  standalone: true,
  imports: [CommonModule, WorkflowPresetsComponent],
  templateUrl: './workflow-presets-container.component.html',
  styleUrl: './workflow-presets-container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowPresetsContainerComponent implements OnInit {
  workflowPresets$!: Observable<WorkflowPresetsInterface[]>;
  workflowDefaultPresets: WorkflowPresetsInterface[] = [
    {
      clusterType: 'local',
      driverMemory: '1G',
      executorCores: DEFAULT_EXECUTOR_CORES,
      executorMemory: '2G',
      hadoopUser: null,
      id: 1,
      isDefault: false,
      isEditable: true,
      name: 'default',
      numExecutors: DEFAULT_NUMB_OF_EXECUTORS,
      params: '',
      totalExecutorCores: DEFAULT_EXECUTOR_CORES * DEFAULT_NUMB_OF_EXECUTORS,
      uri: 'local[*]',
      userIP: '',
    },
  ];
  loading$!: Observable<boolean>;
  id: number = +this.activatedRoute?.snapshot?.params['id'];
  editMode = !!this.id;

  constructor(
    private activatedRoute: ActivatedRoute,
    private history: HistoryBackService,
    public workflowPresetsDomainService: WorkflowPresetsDomainService,
  ) {}

  ngOnInit(): void {
    if (this.editMode) {
      this.workflowPresetsDomainService.getItem(this.id);
    }
  }

  onSubmit(data: WorkflowPresetsInterface): void {
    if (this.editMode) {
      this.workflowPresetsDomainService.update(data);
    } else {
      this.workflowPresetsDomainService.add(data);
    }
  }

  onBack(): void {
    this.history.back(getPresetsPath());
  }
}
