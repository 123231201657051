import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { WorkflowSettingsSchedulesFormComponent } from '../../../workflow-settings';
import { SchedulesListItem } from '../../models';
import { WorkflowPresetsInterface } from '../../../workflow-presets';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { WorkflowInfo } from '@selfai-platform/pipeline-common';
import { AsyncPipe, NgIf } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BreadcrumbsMenuItem, BreadcrumbsMenuService, KE_ROOT_ROUTE, KE_SCHEDULES_PATH } from '@selfai-platform/shell';
import { PanelModule } from 'primeng/panel';
import { Button } from 'primeng/button';
import { SkeletonModule } from 'primeng/skeleton';
import { FloatLabelModule } from 'primeng/floatlabel';
import { SchedulesPermissionService } from '../../services/schedules-permission.service';
import { SchedulerPermissionAction } from '@selfai-platform/shared';
import { combineLatest, take } from 'rxjs';

@Component({
  selector: 'selfai-platform-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    WorkflowSettingsSchedulesFormComponent,
    DropdownModule,
    FormsModule,
    NgIf,
    PanelModule,
    Button,
    SkeletonModule,
    FloatLabelModule,
    TranslateModule,
    AsyncPipe,
  ],
})
export class ScheduleComponent implements OnInit, OnChanges {
  public id: string = this.activatedRoute?.snapshot?.params['id'];
  public editMode = !!this.id;
  public canUpdate: boolean = false;
  public selectedWorkflow: WorkflowInfo;
  public currentSchedule: SchedulesListItem;
  public schedulesFormData: SchedulesListItem;
  public breadcrumbItems: BreadcrumbsMenuItem[] = [
    {
      label: this.translate.instant('shell.menu.schedules'),
      routerLink: ['/', KE_ROOT_ROUTE, KE_SCHEDULES_PATH],
    },
  ];

  @Input() schedules: SchedulesListItem[];
  @Input() workflows: WorkflowInfo[];
  @Input() presets: WorkflowPresetsInterface[];
  @Input() isLoading: boolean;

  @Output() backEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() submitEvent: EventEmitter<SchedulesListItem> = new EventEmitter<SchedulesListItem>();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly activatedRoute: ActivatedRoute,
    private readonly translate: TranslateService,
    private readonly breadcrumbsMenuService: BreadcrumbsMenuService,
    private readonly permissionService: SchedulesPermissionService,
    private readonly router: Router,
  ) {}

  public onBackEvent(): void {
    this.backEvent.emit();
  }

  public onSubmitEvent(): void {
    this.submitEvent.emit({ ...this.currentSchedule, ...this.schedulesFormData, workflowId: this.selectedWorkflow.id });
  }

  public setScheduleData(data: unknown): void {
    this.schedulesFormData = { ...this.currentSchedule, ...data as SchedulesListItem };
  }

  public ngOnInit(): void {
    if (!this.editMode) {
      this.breadcrumbItems = [...this.breadcrumbItems, {
        label: `${this.translate.instant('shell.bc.schedules.label.create')}`,
        routerLink: ['/', KE_ROOT_ROUTE, KE_SCHEDULES_PATH, 'create'],
      }];
      this.breadcrumbsMenuService.setBreadcrumbsMenu(this.breadcrumbItems);
      this.changeDetectorRef.detectChanges();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['schedules']?.currentValue?.length && this.editMode && !this.currentSchedule) {
      this.currentSchedule = JSON.parse(JSON.stringify(this.schedules.find((schedule) => {
        return schedule.id === this.id;
      })));
      this.breadcrumbItems = [...this.breadcrumbItems, {
        label: `${this.translate.instant('shell.bc.schedules.label.edit')}`,
        routerLink: ['/', KE_ROOT_ROUTE, KE_SCHEDULES_PATH, 'edit', this.currentSchedule?.id],
      }];
      this.breadcrumbsMenuService.setBreadcrumbsMenu(this.breadcrumbItems);
      this.changeDetectorRef.detectChanges();
    }
    if (this.workflows && this.currentSchedule) {
      this.selectedWorkflow = this.workflows.find((workflow) => workflow.id === this.currentSchedule.workflowId);
    }
    combineLatest([
      this.permissionService.checkPermission(SchedulerPermissionAction.Get),
      this.permissionService.checkPermission(SchedulerPermissionAction.Update),
    ]).pipe(take(1)).subscribe(([canView, canUpdate]) => {
      if (!canView) {
        this.router.navigate(['access-denied']).then();
      }
      this.canUpdate = canUpdate;
    })
    this.changeDetectorRef.detectChanges();
  }
}
