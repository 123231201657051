import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Observable, combineLatest, map, takeUntil, switchMap, of, tap } from 'rxjs';
import { CheckboxModule } from 'primeng/checkbox';
import { FileUploadModule } from 'primeng/fileupload';
import { MessagesModule } from 'primeng/messages';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { TreeSelectModule } from 'primeng/treeselect';
import { PrimeTemplate, TreeNode } from 'primeng/api';
import { SpoilerComponent } from '@selfai-platform/shell';
import { AlertService, DestroyService, readJsonFile } from '@selfai-platform/shared';
import { WorkflowInfo } from '@selfai-platform/pipeline-common';
import { IFunctionImportMessages, UserFunctionApi, INamespace } from '../../models';
import { UserFunctionsApiService } from '../../services';
import { NamespacesListStore, WorkflowsListStore } from '../../store';
import { UserFunctionsControls } from '../../static';

@Component({
  selector: 'selfai-platform-user-functions-import-dialog',
  templateUrl: './user-functions-import-dialog.component.html',
  styleUrls: ['./user-functions-import-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CheckboxModule,
    FileUploadModule,
    MessagesModule,
    NgForOf,
    NgIf,
    PrimeTemplate,
    SpoilerComponent,
    FormsModule,
    ReactiveFormsModule,
    TreeSelectModule,
    AsyncPipe,
  ],
  standalone: true,
})
export class UserFunctionsImportDialogComponent implements OnInit, OnDestroy {
  public overrideUserFunctions = false;
  public needToUpdateFunctionsList = false;
  public uploadStatuses$: Observable<IFunctionImportMessages[]>;

  public workflows$: Observable<WorkflowInfo[]>;
  public namespaces$: Observable<INamespace[]>;

  public scopeList: Observable<TreeNode[]>;
  public selectedScopes: TreeNode[] = [];

  constructor(
    private readonly alertService: AlertService,
    private readonly namespacesStore: NamespacesListStore,
    private readonly workflowsStore: WorkflowsListStore,
    private readonly destroy$: DestroyService,
    private readonly userFunctionsApiService: UserFunctionsApiService,
    private readonly ref: DynamicDialogRef,
  ) {
    this.namespaces$ = this.namespacesStore.entities$ as Observable<INamespace[]>;
    this.workflows$ = this.workflowsStore.entities$ as Observable<WorkflowInfo[]>;
  }

  public ngOnInit(): void {
    this.namespacesStore.getAll();
    this.workflowsStore.getAll();
    this.scopeList = combineLatest({ workflows: this.workflows$, namespaces: this.namespaces$ }).pipe(
      takeUntil(this.destroy$),
      map(({ workflows, namespaces }) => UserFunctionsControls.createScopeList(workflows, namespaces)),
    );
  }

  public onError(): void {
    this.alertService.error(`Error. Can't upload file`);
  }

  public importUserFunction(data: { files: File[] }): void {
    this.uploadStatuses$ = readJsonFile(data.files[0]).pipe(
      switchMap((data) =>
        this.userFunctionsApiService.importUserFunctions(
          data as UserFunctionApi[],
          this.selectedScopes?.map((scope) => scope.data),
          this.overrideUserFunctions,
        ),
      ),
      tap((messages: IFunctionImportMessages[]) => {
        this.needToUpdateFunctionsList = !!messages.find((message) => message.success);
      }),
      takeUntil(this.destroy$),
    );
  }

  public clearStatuses(): void {
    this.uploadStatuses$ = of([] as IFunctionImportMessages[]);
  }

  public ngOnDestroy(): void {
    this.ref.close(this.needToUpdateFunctionsList);
  }
}
